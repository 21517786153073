/* src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: 'Inter', sans-serif;
}

.dndflow {
  display: flex;
  height: 100vh;
}

.reactflow-wrapper {
  flex: 1;
  height: 100%;
  background-color: #f0f0f0;
}

.sidebar {
  padding: 10px;
  border-radius: 10px;
  background-color: #ffffff;
  border-right: 1px solid #e4e4e4;
}

.dndnode {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #222;
  background-color: #fff;
  cursor: grab;
}

.text-node, .url-buttons-node, .trigger-node {
  padding: 10px;
  border-radius: 3px;
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.text-node {
  background-color: #f3f4f6;
}

.url-buttons-node {
  background-color: #fef3c7;
}

.trigger-node {
  background-color: #d1fae5;
}
/* Add this CSS to your stylesheet */
.hand-cursor {
  cursor: grab;
}

.hand-cursor:active {
  cursor: grabbing;
}


/* Add this to your CSS file */
.switch {
  position: relative;
  display: inline-block;
  width: 34px;
  height: 20px;
  margin-left: 2px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider{
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider2{
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
}
.slider2:before {
  position: absolute;
  content: "";
  height: 8px;
  width: 8px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 1px; /* Adjust the width as needed */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.5); /* Color of the scrollbar */
  border-radius: 10px; /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.1); /* Background of the scrollbar track */
}

input:checked + .slider {
  background-color: #2196F3;
}

input:checked + .slider2 {
  background-color: #2196F3;
}

input:checked + .slider:before {
  transform: translateX(14px);
}

input:checked + .slider2:before {
  transform: translateX(14px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.slider2.round {
  border-radius: 34px;
}

.slider2.round:before {
  border-radius: 50%;
}
/* index.css */

.edgebutton {
  cursor: pointer;
  background: red;
  border: none;
  color: white;
  font-size: 12px;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  text-align: center;
}

.edgebutton-foreignobject {
  pointer-events: none;
}

.edgebutton-foreignobject div {
  pointer-events: all;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
